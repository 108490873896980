<template>
  <loading-spinner v-if="$store.getters['authenticatingUser']"></loading-spinner>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // handleLoginEvent(data) {
    //   console.log(">> Callback handleLoginEvent");
    //   // this.$store.dispatch('setLoggingState', false)
    //   console.log(data)
    //   if (data.loggedIn && data.profile) {
    //     this.$router.push(data.appState._redirectTo || "/dashboard");
    //   } else {
    //     // TODO show error message
    //     this.$router.push("/");
    //   }
    // }
  },
  created() {
    this.$auth.handleAuthentication().catch(err => {
      // TODO show error message
      console.log("error in authentication");
      console.log(err);
      this.$router.push("/");
    });
  }
};
</script>

<style lang="scss" scoped>
.callback {
  background: red;
}
</style>
